import { Link } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'

import errorIcon from '../images/Pages/errorIcon.png'
import icon from '../images/Pages/favicon.ico'
import logoHeader from '../images/Pages/logoHeader.png'

// styles
const mainStyles = {
  fontFamily: `NewJune, Avenir Next, Helvetica Neue, Arial, sans-serif`,
  lineHeight: `1.5`,
  color: `#51284f`,
  height: `100vh`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `center`,
}

const headerStyles = {
  display: `flex`,
  flexDirection: `column`,
  alignItems: `flex-start`,
  padding: `0px`,

  position: `absolute`,
  width: `100%`,
  height: `88px`,
  left: `0px`,
  top: `0px`,
}

const headerItemsContainerStyles = {
  display: `flex`,
  flexDirection: `row`,
  alignItems: `center`,
  padding: `0px 0px 0px 40px`,

  position: `static`,
  width: `100%`,
  height: `88px`,
  left: `calc(50% - 50%)`,
  top: `0px`,

  /* Gradients/Text/Headlines */
  background: `linear-gradient(90deg, #51284F 0%, #A50050 78.05%)`,
}

const headerLogoContainerStyles = {
  position: `static`,
  width: `200px`,
  height: `46px`,
  left: `40px`,
  top: `21px`,
  margin: `0px 24px`,
}

const headerLogoStyles = {
  width: `200px`,
  height: `46px`,
}

const errorContainerStyles = {
  display: `flex`,
  flexDirection: `row`,
  alignItems: `flex-start`,
  padding: `2rem`,
  gap: `1rem`,
}

const errorMessageContainerStyles = {
  display: `flex`,
  flexDirection: `column`,
  alignItems: `flex-start`,
  padding: `0px`,
}

const errorMessageStyles = {
  margin: `0 0 60px 0`,
}

const errorHeaderStyles = {
  fontWeight: `bold`,
}

const errorIconContainerStyles = {
  position: `static`,
  left: `0px`,
  top: `0px`,
}

const errorIconStyles = {
  width: `72px`,
  height: `72px`,
}

// markup
const NotFoundPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="shortcut icon" type="image/x-icon" href={icon} />
      <title>Error 404 | Laboral Kutxa</title>
    </Helmet>
    <main style={mainStyles}>
      <div style={headerStyles}>
        <div style={headerItemsContainerStyles}>
          <div style={headerLogoContainerStyles}>
            <a href="/" alt="home">
              <img src={logoHeader} style={headerLogoStyles} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div style={errorContainerStyles}>
        <div style={errorIconContainerStyles}>
          <img src={errorIcon} style={errorIconStyles} alt="" />
        </div>
        <div style={errorMessageContainerStyles}>
          <div style={errorMessageStyles}>
            <h1 style={errorHeaderStyles}>Vaya... ¿dónde está esta página?</h1>
            <p>
              Puede que la página a la que intentas acceder tenga una nueva dirección o tal vez ya no esté disponible.
            </p>
            <Link to="/es">Volver a la página de inicio</Link>.
          </div>
          <div style={errorMessageStyles}>
            <h1 style={errorHeaderStyles}>Aupa... non dago orrialde hau?</h1>
            <p>Atzitzen saiatzen ari zaren orrialdeak helbide berri bat izan dezake edo erabilgarri ez egotea.</p>
            <Link to="/eu">itzuli hasierako orrialdera</Link>.
          </div>
        </div>
      </div>
    </main>
  </>
)

export default NotFoundPage
